<template>
  <div class="Article">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'Article',
  components: {},
  mounted() {},
  data: () => ({
    article: {
      title: '',
      tags: [],
      content: null,
    }
  })
}
</script>
